import { Http } from './request';
import { User } from '@robogo/common';

export const apiUser = {
  me() {
    return Http.Get<User>('/api/users/me');
  },

  selectWorkspace(id: string) {
    return Http.Put<User>('/api/users/workspace/' + id);
  },
};
