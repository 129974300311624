import { APIResponse } from '@robogo/common';
import { UseFetchOptions } from 'nuxt/app';
import { useUserStore } from '~/stores/user';

const baseUrl = '';

const errorResponse: APIResponse = {
  success: false,
  code: 0,
  message: '',
  data: null,
};

type fetchOptionV1 = {
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  query?: Record<string, any>;
  body?: Record<string, any>;
  params?: Record<string, any>;
};

async function fetchV1<T = any>(opt: fetchOptionV1): Promise<APIResponse<T>> {
  const { url, ...reset } = opt;
  try {
    const request = baseUrl + url;
    const res = await $fetch<APIResponse>(request, {
      headers: { Authorization: getAuthorization() },
      ...reset,
    });
    return res;
  } catch (error) {
    return handlerError(error);
  }
}

type FetchOption<T = any> = {
  url: string;
} & UseFetchOptions<T>;

async function fetch<T = any>(opt: FetchOption<APIResponse<T>>): Promise<APIResponse<T>> {
  const { url, ...reset } = opt;
  const request = baseUrl + url;
  const { data, error } = await useFetch(request, { headers: { Authorization: getAuthorization() }, ...reset });

  if (error.value) {
    const { statusCode, message, response } = error.value;

    if (statusCode === 401) {
      const userStore = useUserStore();
      userStore.logout();

      const router = useRouter();
      router.push('/passport/login');
    }

    const errorMessages = response?._data?.message || message || 'Error';

    return {
      success: false,
      code: statusCode || 500,
      message: errorMessages,
      data: <T>null,
    };
  }

  if (data.value !== null) {
    return data.value;
  }

  return {
    success: false,
    code: 500,
    message: 'Data is null',
    data: <T>null,
  };
}

async function Get<T = any>(url: string, query?: Record<string, any>): Promise<APIResponse<T>> {
  return fetch({ url, method: 'GET', query });
}

async function Post<T = any>(url: string, body?: Record<string, any>): Promise<APIResponse<T>> {
  return fetch({ url, method: 'POST', body });
}

async function Put<T = any>(url: string, body?: Record<string, any>): Promise<APIResponse<T>> {
  return fetch({ url, method: 'PUT', body });
}

async function Delete<T = any>(url: string, body?: Record<string, any>): Promise<APIResponse<T>> {
  return fetch({ url, method: 'DELETE', body });
}

function getAuthorization() {
  const userStore = useUserStore();
  if (userStore.token) {
    return 'Bearer ' + userStore.token;
  }
  return '';
}

function handlerError(error: any) {
  // @ts-ignore
  if (error.response._data) {
    // @ts-ignore
    return error.response._data;
  }
  errorResponse.message = '' + error;
  console.log('error:', error);
  return errorResponse;
}

export const Http = { Get, Post, Put, Delete };
