import { defineStore } from 'pinia';
import { User, UserLoginReq } from '@robogo/common';
import { APIPassport } from '~/api/passport';
import { apiUser } from '~/api/user';

export const useUserStore = defineStore('auth', {
  // @ts-ignore
  persist: true,
  state: () => ({
    user: <Partial<User> | null>null,
    token: '',
  }),
  getters: {
    isLoggedIn: (state) => !!state.user,
    workspace: (state) => state.user?.workspace,
  },
  actions: {
    async login(req: UserLoginReq) {
      const res = await APIPassport.login(req);
      const { data, success } = res;
      this.token = data?.accessToken || '';
      if (!success) {
        await this.logout();
        return res;
      }
      return this.me();
    },

    async me() {
      const res = await apiUser.me();
      const { data, success } = res;
      if (!success) {
        return res;
      }
      this.user = data;
      return res;
    },

    async logout() {
      this.user = null;
      this.token = '';
    },
  },
});
